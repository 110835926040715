
import * as ipxRuntime$ML5IyT1weZ from '/opt/buildhome/repo/node_modules/.pnpm/@nuxt+image@1.8.1_ioredis@5.4.1_magicast@0.3.5_rollup@4.27.4/node_modules/@nuxt/image/dist/runtime/providers/ipx'
import * as noneRuntime$554XMdcfo1 from '/opt/buildhome/repo/node_modules/.pnpm/@nuxt+image@1.8.1_ioredis@5.4.1_magicast@0.3.5_rollup@4.27.4/node_modules/@nuxt/image/dist/runtime/providers/none'
import * as customimgproxyRuntime$mYUq6somPH from '/opt/buildhome/repo/web/imgprovider/imgproxy.ts'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 600,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {},
  "provider": "none",
  "domains": [
    "famitei.mobi",
    "famitei.jp",
    "www.famitei.co.jp",
    "famitei.co.jp",
    "static.famitei.co.jp",
    "i.ytimg.com",
    "s3.ap-northeast-1.amazonaws.com"
  ],
  "alias": {},
  "densities": [
    1,
    2,
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['ipx']: { provider: ipxRuntime$ML5IyT1weZ, defaults: {"maxAge":2592000} },
  ['none']: { provider: noneRuntime$554XMdcfo1, defaults: {} },
  ['customimgproxy']: { provider: customimgproxyRuntime$mYUq6somPH, defaults: {"baseURL":"https://image.famitei.co.jp"} }
}
        